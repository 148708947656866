import { render, staticRenderFns } from "./orderList2.vue?vue&type=template&id=7d36d37c&scoped=true&"
import script from "./orderList2.vue?vue&type=script&lang=js&"
export * from "./orderList2.vue?vue&type=script&lang=js&"
import style0 from "./orderList2.vue?vue&type=style&index=0&id=7d36d37c&scoped=true&lang=css&"
import style1 from "./orderList2.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d36d37c",
  null
  
)

export default component.exports