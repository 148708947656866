<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Delivery Order') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Upload Order') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <b-row>
      <b-col lg="3" class="ll-padding">
        <b-card class="ll-card1">
          <b-card-body>
            <b-form-group
              :label="$t('Order Template To Upload')"
              label-for="s"
            >
              <v-select
                :options="temList"
                v-model="template"
                label="codeDescription"
                @input="selTem"
              >
                <template slot="option" slot-scope="option">
                  {{ option.codeDescription == 'LMD' ? 'Last Mile Delivery' : 'Cross Border Service' }} Template
                </template>

                <template slot="selected-option" slot-scope="option">
                  {{ option.codeDescription == 'LMD' ? 'Last Mile Delivery' : 'Cross Border Service' }} Template
                </template>
              </v-select>
            </b-form-group>
            <div class="ll-btnBox">
              <b-button
                variant="outline-secondary"
                @click="downTem"
                :disabled="disabled"
                :style="cursor"
              >
                <span class="ll-text">{{ $t('Download template') }}</span>
              </b-button>
            </div>
            <div class="ll-btnBox">
              <b-button
                variant="outline-secondary"
                @click="$refs.refInputEl.click()"
                :disabled="disabled"
                :style="cursor"
              >
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="inputRenderer($event)"
                  accept=".xlsx"
                >
                <span class="d-none d-sm-inline">{{ $t('Upload order') }}</span>
              </b-button>
              <span>{{fileName}}</span>
            </div>

            <div>
              <strong>Latest Template Version:</strong>
              <ul>
                <li>Last Mile Delivery Template: VERSION:1.7(Mar 2025)</li>
                <li>Cross Border Service Template: VERSION:1.1</li>
              </ul>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="9">
        <b-card class="ll-card1">
          <b-card-header>
            <b-card-title>{{ $t('Last 10 Upload Record') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-table :items="items" :fields="fields"
              :borderless='false' :hover="true"
              :sticky-header="true"
              v-permission="[146]"
              :busy="isBusy"
              class="ll-table"
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(id)="scope">
                <span>{{scope.item.progressMax - scope.item.progressNow}}</span>
              </template>
              <template #cell(input)="scope">
                <b-button
                  class="btn-icon"
                  variant="flat-info"
                  :href="scope.value.slice(13, -2)"
                >
                  <feather-icon icon="ArrowDownIcon" />
                </b-button>
              </template>
              <template #cell(output)="scope">
                <b-button
                  class="btn-icon"
                  variant="flat-info"
                  :href="scope.value"
                  v-if="scope.value !== null && scope.value !== ''"
                >
                  <feather-icon icon="ArrowDownIcon" />
                </b-button>
              </template>
              <template #cell(progressNow)="scope">
                <!-- <b-badge
                  pill
                  class="ll-badge"
                  :variant="scope.item.progressStatus === 'success' ? 'light-info' :'light-danger'"
                >
                  {{scope.item.progressStatus}}
                </b-badge> -->
                <!-- <span>{{((scope.item.progressNow/scope.item.progressMax)*100).toFixed(2)}}</span> -->
                <b-progress
                  key="info"
                  :max="scope.item.progressMax"
                  :variant="(scope.item.progressStatus === 'success') ? 'info': 'danger'"
                  class="progress-bar-info"
                >
                  <b-progress-bar
                    :value="scope.item.progressNow"
                    variant="info"
                    :label="`${((scope.item.progressNow/scope.item.progressMax) * 100).toFixed(2)}%`"
                  />
                  <!-- <span class="ll-fail" v-if="scope.item.progressStatus !== 'success'">{{scope.item.progressStatus}}</span> -->
                </b-progress>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem,
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody,
  BFormGroup, BButton, BTable, BProgress, BProgressBar, BBadge, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debounce } from "@/libs/fun.js"
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    vSelect,
    BFormGroup,
    BButton,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BProgress,
    BProgressBar,
    BBadge,
    BSpinner,
  },
  directives: {
    permission,
  },
  data() {
    return{
      items: [],
      fields: [
        { key: 'createdAt', label: this.$t('Template Uploaded Time') },
        { key: 'operator', label: this.$t('Template Uploaded By') },
        { key: 'taskName', label: this.$t('Template type') },
        // { key: 'input', label: 'File name', class: 'll-width' },
        { key: 'progressNow', label: this.$t('Upload Status'), class: 'll-progress' },
        { key: 'progressMax', label: this.$t('Total Orders') },
        { key: 'id', label: this.$t('Orders With Error') },
        { key: 'input', label: this.$t('Download Source File') },
        { key: 'output', label: this.$t('Download Error Message') },
      ],
      windowHeight: document.documentElement.clientHeight,
      cardHei: document.documentElement.clientHeight *0.82,
      cardHei1: document.documentElement.clientHeight *0.82,
      temList: [],
      template: null,
      down: null,
      fileName: null,
      type: null,
      disabled: true,
      cursor: {
        cursor: 'no-drop',
      },
      isBusy: true,
    }
  },
  computed: {
    style1() {
      return {
        '--height': this.cardHei + 'px'
      }
    },
    style2() {
      return {
        '--height1': this.cardHei1 + 'px'
      }
    },
  },
  watch: {
    windowHeight(val) {
      this.cardHei = val *0.82
      this.cardHei1 = val *0.82
    },
    template(val) {
      if (val !== null) {
        this.disabled = false
        this.cursor.cursor = 'pointer'
      } else {
        this.disabled = true
        this.cursor.cursor = 'no-drop'
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm=>{
      if (from.name == 'salesOrder') {
        vm.getProcessStatus1()
      }
    })
  },
  created() {},
  mounted() {
    this.getProcessStatus()
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        this.windowHeight = window.fullHeight
      })()
    }
    this.timer = setInterval(() => {
      setTimeout(() => {
        // setTimeout是自带清除定时器
        this.getProcessStatus()
      }, 0)
    }, 5000)
    this.queryUpload()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    inputRenderer(e) {
      const file = e.target.files[0]
      this.fileName = file.name
      let fd = new FormData
      fd.append('file', file)
      // console.log(fd)
      if (file.name !== null) {
        this.$http.post(`/import/uploadLmdOrder/${this.type}`, fd).then(res=> {
          if (res.data.code === '200') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload' + res.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    queryUpload() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'uploadOrderType'}}).then(res => {
        this.temList = res.data.data
      })
    },
    selTem(d) {
      this.template = d ? d.codeDescription : null
      this.type = d ? d.codeName : null
    },
    downTem: debounce(function() {
      let url = axios.defaults.baseURL+`/import/template/${this.type}`
      window.open(url, "_self")
    }, 1000),
    getProcessStatus() {
      this.$http.post('/import/getProcessStatus').then(res => {
        this.items = res.data.data
        this.isBusy = false
      })
    },
    getProcessStatus1() {
      this.$http.post('/import/getProcessStatus').then(res => {
        let info = res.data.data
        if (info[0].taskName == 'FOMS_LABEL' && info[0].output) {
          let url = info[0].output
          window.open(url, "_self")
        }
      })
    },
  },
}
</script>

<style scoped>
.ll-btnBox{
  margin-bottom: 1rem;
  text-align: center;
}
.ll-btnBox .btn{
  width: 100%;
}
.ll-text{
  color: #409eff;
}
.ll-card1{
  height: 80vh;
}
@media (min-width: 2000px) {
  .ll-card1 {
    height: 85vh;
  }
}
.ll-card2{
  height: var(--height1);
}
.ll-padding{
  padding-right: 0;
}
.ll-fail{
  margin: auto;
}
</style>
<style>
/* .ll-width{
  width: 10%;
  text-align: center;
} */
.ll-progress{
  width: 20%;
  text-align: center;
}
.ll-progress .progress{
  height: 2rem;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>